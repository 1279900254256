import { post } from './Http'

export const AuthSrv = {
  login: async (userName: string, password: string) => {
    return await post<string>('/api/auth/login', {
      userName,
      password,
    })
  },
}
