import { Form, Input, InputNumber, PageHeader, Space } from 'antd'
import { FC } from 'react'
import Panel from '../../Components/Panel'

interface SettingsProps {}

const Settings: FC<SettingsProps> = () => {
  return (
    <>
      <PageHeader title="系统配置">
        {' '}
        <Form labelCol={{ span: 2 }} wrapperCol={{ span: 8, offset: 1 }}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Panel>
              <PageHeader title="YouTube资源设置">
                <Form.Item label="视频获取周期">
                  <InputNumber addonAfter="分钟" defaultValue={120} />
                </Form.Item>

                <Form.Item label="视频清理周期">
                  <InputNumber addonAfter="天" defaultValue={7} />
                </Form.Item>
              </PageHeader>
            </Panel>

            <Panel>
              <PageHeader title="域名设置">
                <Form.Item label="视频域名">
                  <Input addonBefore="https://" />
                </Form.Item>

                <Form.Item label="API域名">
                  <Input addonBefore="https://" />
                </Form.Item>
              </PageHeader>
            </Panel>
          </Space>
        </Form>
      </PageHeader>
    </>
  )
}

export default Settings
