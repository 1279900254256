import { DatePicker, Form, Input, Modal, Spin } from 'antd'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import TextEditor from '../../../Components/TextEditor'
import { addNews, getNewsInfo, updateNews } from '../../../Services/NewsService'
import { UseModalProps } from '../../../Utils/Hooks'

const Editor: FC<UseModalProps> = ({ params, close, save, visible }) => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const [confirmLoading, setConfirmLoading] = useState(false)

  async function onSave(formData: any) {
    const isEdit = !!params

    formData.publishTime = formData.publishTime.format('YYYY-MM-DD HH:ss:mm')
    setConfirmLoading(true)
    const success = isEdit
      ? await updateNews(formData)
      : await addNews(formData)
    setConfirmLoading(false)
    success && save()
  }

  useEffect(() => {
    form.resetFields()

    if (!visible) return
    if (!params) return

    setLoading(true)
    getNewsInfo(params).then((res) => {
      form.setFieldsValue({
        ...res,
        publishTime: moment(res.publishTime),
      })
      setLoading(false)
    })

    // eslint-disable-next-line
  }, [visible, params])

  return (
    <Modal
      visible={visible}
      onCancel={close}
      onOk={form.submit}
      width={800}
      title="编辑新闻"
      confirmLoading={confirmLoading}
      maskClosable={false}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 19, offset: 1 }}
          onFinish={onSave}
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="title" label="新闻标题">
            <Input />
          </Form.Item>

          <Form.Item name="publishTime" label="发布时间">
            <DatePicker showTime />
          </Form.Item>

          <Form.Item name="content" label="新闻内容">
            <TextEditor />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

export default Editor
