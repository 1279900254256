export interface IHttpRes<T> {
  code: number
  error: IHttpError
  result: T
  success: boolean
}

export interface IHttpError {
  code: number
  message: string
}

export interface IPage<T> {
  items: T[]
  totalCount: number
}

export const DefaultPage = {
  items: [],
  totalCount: 0,
}
