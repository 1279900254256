export class VideoPreviewModel {
  id = ''
  title = ''
  publishTime = ''
  duration = ''
  channelTitle = ''
}

export class VideoFormData {
  id = ''
  title = ''
  publishTime = ''
  channelTitle = ''
}
