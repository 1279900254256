import React from 'react'
import ReactDOM from 'react-dom'
import { Routes, BrowserRouter, Route } from 'react-router-dom'
import Login from './pages/Login'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import Channel from './pages/Channel'
import MainLayout from './MainLayout'
import Video from './pages/Video'
import News from './pages/News'
import Settings from './pages/Settings'

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<Channel />} />
        <Route path="/video" element={<Video />} />
        <Route path="/news" element={<News />} />
        <Route path="/settings" element={<Settings />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
)

reportWebVitals()
