export class ChannelPreviewModel {
  id = ''
  title = ''
  avatar = ''
  updateTime = ''
}

export class ChannelModel extends ChannelPreviewModel {
  banner = ''
  order = 0
  description = ''
  autoUpdate = true
}
