import { FC, useEffect, useState } from 'react'
import BraftEditor, { ControlType, EditorState } from 'braft-editor'
import { message } from 'antd'
import { validate } from '../Utils/Helper'
import http from '../Utils/Http'
import 'braft-editor/dist/index.css'

interface TextEditorProps {
  controls?: ControlType[]
  value?: string
  onChange?: (html: string) => void
}

const TextEditor: FC<TextEditorProps> = (props) => {
  const defaultControls: ControlType[] = [
    'font-size',
    'bold',
    'italic',
    'underline',
    'text-color',
    'separator',
    'text-align',
    'separator',
    'media',
  ]

  function onChange(editorState: EditorState) {
    if (!props.onChange) return
    const html = editorState.toHTML()
    setContent(html)
    props.onChange(html)
  }

  function uploadFile(param: any) {
    const result = validate(param.file as File)
    if (!result.success) {
      param.error({ msg: result.message })
      message.error({ content: result.message })
      return false
    }

    const serverURL = http.defaults.baseURL + '/api/admin/uploader/image'
    const xhr = new XMLHttpRequest()
    const fd = new FormData()

    const successFn = async (event: any) => {
      // 假设服务端直接返回文件上传后的地址
      // 上传成功后调用param.success并传入上传后的文件地址
      const res = JSON.parse(xhr.responseText)
      const urls = res.result
      console.log(res)
      param.success({
        url: urls[0],
      })
    }

    const progressFn = (event: any) => {
      // 上传进度发生变化时调用param.progress
      param.progress((event.loaded / event.total) * 100)
    }

    const errorFn = (response: any) => {
      // 上传发生错误时调用param.error
      param.error({
        msg: 'unable to upload.',
      })
    }

    const token = sessionStorage.getItem('token')
    xhr.upload.addEventListener('progress', progressFn, false)
    xhr.addEventListener('load', successFn, false)
    xhr.addEventListener('error', errorFn, false)
    xhr.addEventListener('abort', errorFn, false)

    fd.append('file', param.file)
    xhr.open('POST', serverURL, true)
    xhr.setRequestHeader('Authorization', 'Bearer ' + token)
    xhr.send(fd)
  }

  const [content, setContent] = useState('')

  useEffect(() => {
    if (!props.value) return

    if (!content) {
      const html = BraftEditor.createEditorState(props.value)
      setContent(html)
    }
    // eslint-disable-next-line
  }, [props.value])

  return (
    <BraftEditor
      style={{ border: '1px solid #ddd' }}
      value={content}
      onChange={onChange}
      media={{ uploadFn: uploadFile }}
      controls={props.controls ?? defaultControls}
    />
  )
}

export default TextEditor
