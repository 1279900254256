import { message } from 'antd'
import { VideoFormData, VideoPreviewModel } from '../Models/VideoModel'
import { get, post } from '../Utils/Http'
import { DefaultPage, IPage } from '../Utils/Models'

export async function getVideoList(
  pageIndex: number,
  pageSize: number,
  params?: object
) {
  const res = await get<IPage<VideoPreviewModel>>('/api/admin/video/list', {
    pageIndex,
    pageSize,
    ...params,
  })

  return res.result || DefaultPage
}

export async function getVideoInfo(id: string) {
  const res = await post<VideoFormData>('/api/admin/video/' + id)

  return res.result || new VideoFormData()
}

export async function addVideo(video: VideoFormData) {
  const res = await post('/api/admin/video', video)
  if (res.success) {
    message.success('新增视频成功')
  } else {
    message.error(res.error.message)
  }

  return res.success
}

export async function updateVideo(video: VideoFormData) {
  const res = await post('/api/admin/video/update', video)
  if (res.success) {
    message.success('保存视频成功')
  } else {
    message.error(res.error.message)
  }

  return res.success
}

export async function refreshVideo(id: string) {
  const res = await post('/api/admin/video/update/' + id)
  if (res.success) {
    message.success('视频更新成功')
  } else {
    message.error(res.error.message)
  }

  return res.success
}
