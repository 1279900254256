import { useRequest } from 'ahooks'
import { Form, Input, Modal, Select, Spin } from 'antd'
import { FC, useEffect, useState } from 'react'
import { getChannelList } from '../../../Services/ChannelService'
import {
  addVideo,
  getVideoInfo,
  updateVideo,
} from '../../../Services/VideoSearch'
import { UseModalProps } from '../../../Utils/Hooks'

const Editor: FC<UseModalProps> = ({ visible, close, save, params }) => {
  const [form] = Form.useForm()

  const { data: channelList } = useRequest(getChannelList, {
    cacheKey: 'ALL_CHANNEL',
    cacheTime: 1000 * 60,
  })

  const [state, setState] = useState<{
    loading: boolean
    confirmLoading: boolean
  }>({
    loading: false,
    confirmLoading: false,
  })

  async function onSave(formData: any) {
    const isEdit = !!params

    setState({
      loading: false,
      confirmLoading: true,
    })

    const success = isEdit
      ? await updateVideo(formData)
      : await addVideo(formData)

    setState({
      loading: false,
      confirmLoading: false,
    })

    success && save()
  }

  useEffect(() => {
    if (!visible) return

    form.resetFields()

    if (params) {
      setState({
        loading: true,
        confirmLoading: false,
      })
      getVideoInfo(params).then((res) => {
        form.setFieldsValue({ ...res })

        setState({
          loading: false,
          confirmLoading: false,
        })
      })
    }
    // eslint-disable-next-line
  }, [visible, params])

  return (
    <Modal
      visible={visible}
      onCancel={close}
      onOk={form.submit}
      title="编辑视频"
      width={600}
      confirmLoading={state.confirmLoading}
      maskClosable={false}
    >
      <Spin spinning={state.loading}>
        <Form
          form={form}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16, offset: 1 }}
          onFinish={onSave}
        >
          <Form.Item name="id" label="YouTubeID">
            <Input />
          </Form.Item>

          <Form.Item name="title" label="视频标题">
            <Input />
          </Form.Item>

          <Form.Item name="channelId" label="所属频道">
            <Select allowClear disabled={!!params}>
              {channelList?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {!!params ? (
            <Form.Item name="publishTime" label="发布时间">
              <Input />
            </Form.Item>
          ) : null}
        </Form>
      </Spin>
    </Modal>
  )
}

export default Editor
