import { message } from 'antd'
import { ChannelModel, ChannelPreviewModel } from '../Models/ChannelModel'
import { del, get, patch, post } from '../Utils/Http'

export async function getChannelList() {
  const res = await get<ChannelPreviewModel[]>('/api/admin/channel/list')
  return res.success ? res.result : []
}

export async function getChannelInfo(channelId: string) {
  const res = await get<ChannelModel>('/api/admin/channel/' + channelId)
  return res.success ? res.result : new ChannelModel()
}

export async function updateChannel(channel: ChannelModel) {
  const res = await patch('/api/admin/channel', channel)
  if (res.success) {
    message.success('频道保存成功')
  } else {
    message.error(res.error.message)
  }

  return res.success
}

export async function fetchVideo(channelId: string) {
  const res = await post('/api/admin/channel/fetch/' + channelId)
  if (res.success) {
    message.success('更新频道视频成功')
  } else {
    message.error(res.error.message)
  }

  return res.success
}

export async function addChannel(channel: ChannelModel) {
  const res = await post('/api/admin/channel', channel)
  if (res.success) {
    message.success('添加频道成功')
  } else {
    message.error(res.error.message)
  }

  return res.success
}

export async function removeChannel(channelId: string) {
  const res = await del('/api/admin/channel/' + channelId)
  if (res.success) {
    message.success('删除频道成功')
  } else {
    message.error(res.error.message)
  }

  return res.success
}
