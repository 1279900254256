import { ConfigProvider, Layout } from 'antd'
import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import SideMenu from './SideMenu'
import zhCN from 'antd/lib/locale/zh_CN'

const { Sider, Content } = Layout

interface LayoutProps {}

const MainLayout: FC<LayoutProps> = () => {
  return (
    <ConfigProvider locale={zhCN}>
      <Layout style={{ height: '100%' }}>
        <Sider width={220}>
          <SideMenu />
        </Sider>
        <Layout>
          {/* <Header style={{ backgroundColor: '#fff' }}>Header</Header> */}
          <Content style={{ overflowY: 'auto' }}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  )
}

export default MainLayout
