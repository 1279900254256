import {
  AppstoreAddOutlined,
  PaperClipOutlined,
  SettingOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import { FC } from 'react'
import { Link } from 'react-router-dom'

interface SideMenuProps {}

const SideMenu: FC<SideMenuProps> = () => {
  return (
    <>
      <div
        style={{
          height: '64px',
          fontSize: '18px',
          fontWeight: 'bold',
          color: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ margin: '0 12px' }}>真识后台管理</div>
      </div>

      <Menu theme="dark">
        <Menu.Item icon={<AppstoreAddOutlined />} key="channel">
          <Link to="/">频道管理</Link>
        </Menu.Item>
        <Menu.Item icon={<VideoCameraOutlined />} key="video">
          <Link to="/video">视频管理</Link>
        </Menu.Item>
        <Menu.Item icon={<PaperClipOutlined />} key="news">
          <Link to="/news">新闻管理</Link>
        </Menu.Item>
        <Menu.Item icon={<SettingOutlined />} key="settings">
          <Link to="/settings">系统配置</Link>
        </Menu.Item>
      </Menu>
    </>
  )
}

export default SideMenu
