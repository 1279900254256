import { FC } from 'react'

interface PanelProps {}

const Panel: FC<PanelProps> = ({ children }) => {
  const panelStyle = {
    padding: '20px',
    background: '#fff',
  }

  return <div style={panelStyle}>{children}</div>
}

export default Panel
