import { message } from 'antd'
import { NewsModal, NewsPreivewModel } from '../Models/NewsModel'
import { del, get, patch, post } from '../Utils/Http'
import { DefaultPage, IPage } from '../Utils/Models'

export async function getNewsList(pageIndex: number, pageSize: number) {
  const res = await get<IPage<NewsPreivewModel>>('/api/admin/news/list', {
    pageIndex,
    pageSize,
  })

  return res.result || DefaultPage
}

export async function getNewsInfo(id: string) {
  const res = await get<NewsModal>('/api/admin/news/' + id)

  if (res.success) {
    return res.result
  } else {
    message.error(res.error.message)
    return new NewsModal()
  }
}

export async function addNews(news: NewsModal) {
  const res = await post('/api/admin/news', news)

  if (res.success) {
    message.success('发布成功')
  } else {
    message.error(res.error.message)
  }

  return res.success
}

export async function updateNews(news: NewsModal) {
  const res = await patch('/api/admin/news', news)

  if (res.success) {
    message.success('保存成功')
  } else {
    message.error(res.error.message)
  }

  return res.success
}

export async function removeNews(id: string) {
  const res = await del('/api/admin/news/' + id)

  if (res.success) {
    message.success('删除成功')
  } else {
    message.error(res.error.message)
  }

  return res.success
}
