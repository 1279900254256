export class NewsPreivewModel {
  id = ''
  title = ''
  publishTime = ''
}

export class NewsModal {
  id = ''
  title = ''
  content = ''
  publishTime = ''
}
