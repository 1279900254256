export function convertTime(duration: string) {
  var input = duration
  var reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/
  var hours = 0,
    minutes = 0,
    seconds = 0

  const doubleStr = (input: number) =>
    input > 9 ? input.toString() : `0${input}`

  if (reptms.test(input)) {
    var matches = reptms.exec(input)
    if (matches) {
      if (matches[1]) hours = Number(matches[1])
      if (matches[2]) minutes = Number(matches[2])
      if (matches[3]) seconds = Number(matches[3])

      return `${doubleStr(hours)}:${doubleStr(minutes)}:${doubleStr(seconds)}`
    }
  }
  return ''
}

const fileTypes = ['image/jpeg', 'image/png']

const maxSize = 1024 * 1024 * 2

interface ValidResult {
  success: boolean
  message: string
}

export function validate(file: File): ValidResult {
  if (!fileTypes.includes(file.type)) {
    return {
      success: false,
      message: '请上传 jpg, png 格式的图片',
    }
  }

  if (maxSize < file.size) {
    return {
      success: false,
      message: '请上传小于 2M 的图片',
    }
  }

  return { success: true, message: '' }
}
