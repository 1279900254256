import { Button, Form, Input, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { AuthSrv } from '../../Utils/Service'
import { FC } from 'react'
import './index.scss'
import { setToken } from '../../Utils/Http'
import { useRequest } from 'ahooks'

interface LoginProps {}

const Login: FC<LoginProps> = () => {
  const [form] = Form.useForm()

  const navigate = useNavigate()

  const { loading, run: doLogin } = useRequest(requestLogin, {
    manual: true,
  })

  async function requestLogin() {
    const userName = form.getFieldValue('userName')
    const password = form.getFieldValue('password')

    const res = await AuthSrv.login(userName, password)
    console.log(res)
    if (res.success) {
      setToken(res.result)
      navigate('/')
    } else {
      message.error(res.error.message)
    }
  }

  return (
    <>
      <div className="bg"></div>
      <div className="login-page">
        <div className="form-container">
          <div className="login-form">
            <div className="title">后台系统登录</div>
            <Form className="form" form={form} colon={false} onFinish={doLogin}>
              <Form.Item
                name="userName"
                rules={[{ required: true, message: '请输入用户名' }]}
              >
                <Input size="large" placeholder="请输入用户名" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入密码' }]}
              >
                <Input.Password
                  size="large"
                  placeholder="请输入密码"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  style={{ width: '100%' }}
                  size="large"
                >
                  登录
                </Button>
              </Form.Item>

              <Form.Item>
                还没有账号?
                <Button type="link">立即注册</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
