import { message } from 'antd'
import axios, { AxiosError } from 'axios'
import qs from 'qs'
import { IHttpRes } from './Models'

const http = axios.create({
  baseURL: 'https://www.yt-srv.com',
  timeout: 10000,
})

http.interceptors.request.use(async (config) => {
  const token = sessionStorage.getItem('token')

  if (token && config.headers) {
    config.headers['Authorization'] = 'Bearer ' + token
  }

  return config
})

http.interceptors.response.use(
  (response) => {
    if (response.status !== 200) {
      message.error(`请求异常: ${response.status}`)
    }

    return response
  },
  (error: AxiosError) => {
    if (!error.response) throw error

    if (error.response.status === 401) {
      redirectToLogin()
    }

    throw error
  }
)

function redirectToLogin() {
  if (document.location.href.indexOf('/login') === -1) {
    document.location.href = '/login'
  }
}

export async function get<T>(url: string, params?: object) {
  url = url + '?' + qs.stringify(params)
  const res = await http.get(url)

  if (res.data.code === 401) {
    redirectToLogin()
  }

  return res.data as IHttpRes<T>
}

export async function post<T>(url: string, data?: object) {
  const res = await http.post(url, data)
  if (res.data.code === 401) {
    redirectToLogin()
  }

  return res.data as IHttpRes<T>
}

export async function patch<T>(url: string, data?: object) {
  const res = await http.patch(url, data)
  if (res.data.code === 401) {
    redirectToLogin()
  }

  return res.data as IHttpRes<T>
}

export async function del<T>(url: string, data?: object) {
  const res = await http.delete(url, data)
  if (res.data.code === 401) {
    redirectToLogin()
  }

  return res.data as IHttpRes<T>
}

export function setToken(token: string) {
  sessionStorage.setItem('token', token)
}

export default http
