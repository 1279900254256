import { PlusOutlined } from '@ant-design/icons'
import { useAntdTable } from 'ahooks'
import { Button, Modal, PageHeader, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FC } from 'react'
import { NewsPreivewModel } from '../../Models/NewsModel'
import { getNewsList, removeNews } from '../../Services/NewsService'
import { useModal } from '../../Utils/Hooks'
import Editor from './Editor'

interface NewsProps {}

const News: FC<NewsProps> = () => {
  const columns: ColumnsType<NewsPreivewModel> = [
    {
      title: '标题',
      dataIndex: 'title',
      align: 'center',
    },
    {
      title: '发布时间',
      dataIndex: 'publishTime',
      align: 'center',
      width: 200,
    },
    {
      title: '操作',
      align: 'center',
      render: (value, record) => {
        return (
          <>
            <Button type="link" onClick={() => editor.open(record.id)}>
              编辑
            </Button>
            <Button type="link" danger onClick={() => remove(record.id)}>
              删除
            </Button>
          </>
        )
      },
    },
  ]

  const editor = useModal()

  const { tableProps, search } = useAntdTable(doSearch)

  async function doSearch(page: any, params: object) {
    const result = await getNewsList(page.current, page.pageSize)
    return {
      total: result.totalCount,
      list: result.items,
    }
  }

  async function remove(id: string) {
    Modal.confirm({
      title: '提示',
      content: '您确认要删除这条新闻吗？',
      onOk: async () => {
        const result = await removeNews(id)
        result && search.submit()
      },
    })
  }

  return (
    <>
      <PageHeader title="新闻列表">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => editor.open()}
        >
          发布新闻
        </Button>

        <Table
          {...tableProps}
          columns={columns}
          rowKey="id"
          style={{ marginTop: '10px' }}
        />
      </PageHeader>

      <Editor
        {...editor}
        save={() => {
          editor.close()
          search.submit()
        }}
      />
    </>
  )
}

export default News
