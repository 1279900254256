import { Form, Input, InputNumber, Modal, Radio, Spin } from 'antd'
import { FC, useEffect, useState } from 'react'
import ImageUploader from '../../../Components/ImageUploader'
import { ChannelModel } from '../../../Models/ChannelModel'
import {
  getChannelInfo,
  addChannel,
  updateChannel,
} from '../../../Services/ChannelService'
import { UseModalProps } from '../../../Utils/Hooks'

const Editor: FC<UseModalProps> = ({ params, visible, close, save }) => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const [confirmLoading, setConfirmLoading] = useState(false)

  async function onSave(formData: any) {
    const isEdit = !!params

    setConfirmLoading(true)

    const success = isEdit
      ? await updateChannel(formData)
      : await addChannel(formData)

    setConfirmLoading(false)

    success && save()
  }

  useEffect(() => {
    if (!visible) return

    form.resetFields()

    if (params) {
      setLoading(true)
      getChannelInfo(params).then((res) => {
        form.setFieldsValue(res)
        setLoading(false)
      })
    } else {
      form.setFieldsValue(new ChannelModel())
    }
  }, [visible, params, form])

  return (
    <Modal
      title="编辑频道"
      visible={visible}
      onCancel={close}
      onOk={form.submit}
      confirmLoading={confirmLoading}
      width={800}
      maskClosable={false}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16, offset: 1 }}
          onFinish={onSave}
        >
          <Form.Item name="id" label="频道ID" required>
            <Input />
          </Form.Item>

          <Form.Item name="title" label="频道标题" required>
            <Input />
          </Form.Item>

          <Form.Item name="avatar" label="频道头像" required>
            <ImageUploader />
          </Form.Item>

          <Form.Item name="autoUpdate" label="更新模式" required>
            <Radio.Group>
              <Radio value={true}>自动更新</Radio>
              <Radio value={false}>手动更新</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="order" label="排序">
            <InputNumber />
          </Form.Item>

          <Form.Item name="description" label="频道描述">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

export default Editor
