import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { message, Upload, UploadProps } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { FC, useState } from 'react'
import http from '../Utils/Http'
import { IHttpRes } from '../Utils/Models'

interface ImageUploaderProps {
  width?: number
  height?: number
  value?: string
  onChange?: (url: string) => void
}

const ImageUploader: FC<ImageUploaderProps> = ({
  width = 100,
  height = 100,
  value,
  onChange: onUploaded,
}) => {
  const [loading, setLoading] = useState(false)

  const props: UploadProps = {
    beforeUpload: (file: RcFile) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('请上传 JPG/PNG 格式的图片!')
      }

      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        message.error('图片必须小于2MB!')
      }

      return isJpgOrPng && isLt2M
    },
    onChange: (info: any) => {
      if (info.file.status === 'uploading') {
        setLoading(true)
      }

      if (info.file.status === 'done') {
        const res = info.file.response as IHttpRes<string[]>
        let url = ''
        if (res.success) {
          url = res.result[0]
        } else {
          message.error(res.error.message)
        }

        setLoading(false)
        onUploaded && onUploaded(url)
      }
    },
    headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token') },
    action: http.defaults.baseURL + '/api/admin/uploader/image',
    showUploadList: false,
  }

  const img = (
    <img
      src={value}
      width={width}
      height={height}
      alt="uploader"
      style={{ borderRadius: '3px' }}
    />
  )

  const uploadButton = (
    <div
      style={{
        width: width,
        height: height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px dashed #ccc',
        borderRadius: '3px',
      }}
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div>Upload</div>
    </div>
  )

  return (
    <>
      <Upload {...props}>{value ? img : uploadButton}</Upload>
    </>
  )
}

export default ImageUploader
