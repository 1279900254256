import { SearchOutlined } from '@ant-design/icons'
import { useRequest } from 'ahooks'
import { Button, Form, FormInstance, Select } from 'antd'
import { FC } from 'react'
import { getChannelList } from '../../Services/ChannelService'

interface SearchFormProps {
  form: FormInstance
  submit: () => void
}

const SearchForm: FC<SearchFormProps> = ({ form, submit }) => {
  const { data: channelList } = useRequest(getChannelList, {
    cacheKey: 'ALL_CHANNEL',
    cacheTime: 1000 * 60,
  })

  return (
    <Form
      form={form}
      layout="inline"
      labelCol={{ style: { width: '60px' } }}
      wrapperCol={{ style: { width: '200px' } }}
      onFinish={submit}
    >
      <Form.Item name="channelId" label="频道">
        <Select showSearch optionFilterProp="children" allowClear>
          {channelList?.map((item) => (
            <Select.Option key={item.id}>{item.title}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button type="primary" icon={<SearchOutlined />} htmlType="submit">
          搜索
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SearchForm
