import { PlusOutlined } from '@ant-design/icons'
import { useAntdTable } from 'ahooks'
import { Button, Form, PageHeader, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FC } from 'react'
import Panel from '../../Components/Panel'
import { VideoPreviewModel } from '../../Models/VideoModel'
import { getVideoList, refreshVideo } from '../../Services/VideoSearch'
import { convertTime } from '../../Utils/Helper'
import { useModal } from '../../Utils/Hooks'
import Editor from './Editor'
import SearchForm from './SeearchForm'

interface VideoProps {}

const Video: FC<VideoProps> = () => {
  const columns: ColumnsType<VideoPreviewModel> = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
      width: 120,
    },
    {
      title: '频道',
      dataIndex: 'channelTitle',
      align: 'center',
      width: 200,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: '标题',
      dataIndex: 'title',
      align: 'center',
      width: 320,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: '视频时长',
      align: 'center',
      width: 150,
      render: (value, record) => {
        return convertTime(record.duration)
      },
    },
    {
      title: '点击量',
      dataIndex: 'truViewCount',
      align: 'center',
      width: 150,
    },
    {
      title: '发布时间',
      dataIndex: 'publishTime',
      align: 'center',
      width: 200,
    },
    {
      title: '操作',
      align: 'center',
      width: 200,
      render: (value, record) => {
        return (
          <>
            <Button type="link" onClick={() => refresh(record.id)}>
              更新资源
            </Button>
            <Button type="link" onClick={() => editor.open(record.id)}>
              编辑
            </Button>
          </>
        )
      },
    },
  ]

  const [form] = Form.useForm()

  const { search, tableProps } = useAntdTable(doSearch, { form })

  async function doSearch(page: any, params: object) {
    const result = await getVideoList(page.current, page.pageSize, params)
    return {
      total: result.totalCount,
      list: result.items,
    }
  }

  async function refresh(id: string) {
    const success = await refreshVideo(id)
    success && search.submit()
  }

  const editor = useModal()

  return (
    <>
      <PageHeader title="视频列表">
        <Panel>
          <SearchForm form={form} submit={search.submit} />
        </Panel>

        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginTop: '10px' }}
          onClick={() => editor.open()}
        >
          发布视频
        </Button>

        <Table
          columns={columns}
          {...tableProps}
          rowKey="id"
          bordered
          style={{ marginTop: '10px' }}
        />
      </PageHeader>

      <Editor
        {...editor}
        save={() => {
          editor.close()
          search.submit()
        }}
      />
    </>
  )
}

export default Video
