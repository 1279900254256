import { AppstoreAddOutlined } from '@ant-design/icons'
import { useRequest } from 'ahooks'
import { Avatar, Button, Modal, PageHeader, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FC } from 'react'
import { ChannelPreviewModel } from '../../Models/ChannelModel'
import {
  fetchVideo,
  getChannelList,
  removeChannel,
} from '../../Services/ChannelService'
import { useModal } from '../../Utils/Hooks'
import Editor from './Editor'

const Channel: FC = () => {
  const columns: ColumnsType<ChannelPreviewModel> = [
    {
      title: '频道ID',
      dataIndex: 'id',
      width: 300,
      align: 'center',
    },
    {
      title: '频道头像',
      width: 120,
      align: 'center',
      render: (value, record) => {
        return <Avatar src={record.avatar} size={60} />
      },
    },
    {
      title: '频道名称',
      width: 300,
      dataIndex: 'title',
      align: 'center',
    },
    {
      title: '更新时间',
      width: 300,
      dataIndex: 'updateTime',
      align: 'center',
    },
    {
      title: '操作',
      align: 'center',
      render: (value, record) => {
        return (
          <>
            <Button type="link" onClick={() => fetchVideo(record.id)}>
              更新视频
            </Button>
            <Button type="link" onClick={() => editor.open(record.id)}>
              编辑
            </Button>
            <Button type="link" onClick={() => remove(record)} danger>
              删除
            </Button>
          </>
        )
      },
    },
  ]

  const editor = useModal()

  const { loading, data, run } = useRequest(getChannelList, {
    cacheKey: 'ALL_CHANNEL',
    cacheTime: 1000 * 60,
  })

  async function remove(channel: ChannelPreviewModel) {
    Modal.confirm({
      title: '提示',
      content: `您确认要删除 ${channel.title} 吗?`,
      onOk: async () => {
        await removeChannel(channel.id)
        run()
      },
    })
  }

  return (
    <>
      <PageHeader title="频道列表">
        <Button
          type="primary"
          icon={<AppstoreAddOutlined />}
          onClick={() => editor.open('')}
        >
          新增频道
        </Button>
        <Table
          style={{ marginTop: '10px' }}
          bordered
          pagination={false}
          loading={loading}
          dataSource={data}
          columns={columns}
          rowKey="id"
        />
      </PageHeader>

      <Editor
        {...editor}
        save={() => {
          editor.close()
          run()
        }}
      />
    </>
  )
}

export default Channel
