import { useState } from 'react'

interface IState {
  visible: boolean
  params: any
}

export function useModal() {
  const [state, setState] = useState<IState>({
    visible: false,
    params: '',
  })

  function open(params?: any) {
    setState({
      visible: true,
      params: params,
    })
  }

  function close() {
    setState({
      visible: false,
      params: '',
    })
  }

  return {
    params: state.params,
    open: open,
    close: close,
    visible: state.visible,
  }
}

export interface UseModalProps {
  params: any
  visible: boolean
  close: () => void
  save: () => void
}
